<template>
    <div id="app" class="app-showbar column-start-center">
<!--        <NavBar/>-->
        <router-view></router-view>
    </div>
</template>

<script>

    export default {
        name: 'App',
        created() {
            let that = this;
          console.log("张家界欢迎您！祝您旅途愉快！客服电话：0744-5959888")
          console.log("智游天下技术部欢迎您的加入！")
            //设置自适应
            that.changePage();
            window.addEventListener('resize', function () {
                that.changePage();
            }, false);
        },
        methods:{
            widthProportion:function (width) {
                let p = (document.body && document.body.clientWidth || document.getElementsByTagName("html")[0].offsetWidth) / width;
                return p > 1 ? 1 : p < 0.1 ? 0.1 : p;
            },
            changePage:function () {
                let width = 960;// 设置默认最大宽度
                let fontsize = 60;// 默认字体大小
                let rem = this.widthProportion(width) * fontsize;
                let winHeight = (document.body && document.body.clientHeight || document.getElementsByTagName("html")[0].offsetHeight);
                document.getElementsByTagName("html")[0].setAttribute("style", "font-size:" + rem + "px !important");
                this.$store.commit("setRem",rem)
                this.$store.commit("setWinHeight",winHeight)
            }
        }
    }
</script>

<style>
    @import "assets/main.css";

    #app {
        /*position: fixed;*/
        /*top: 0;*/
        /*left: 0;*/
        /*right: 0;*/
        /*left: 0;*/

        z-index: 88;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: left;
        color: #2c3e50;
        background-color: #F2F2F2;
        width: 100%;
        min-height: 100%;
    }
    .app-showbar{
      padding-top: 2.2rem;
    }

  .page{
    width: 16rem;
  }
</style>
