import {STORAGE} from "../config";

export default {
    setSync(key, data) {
        localStorage.setItem([STORAGE[key]], JSON.stringify(data))
    },
    setAsync(key, data, callback = () => {
    }) {
        if (!key) {
            callback({
                //key不能为空，须处理
            })
        }
    },
    getSync(key) {
        let item = localStorage.getItem([STORAGE[key]]);
        return item ? JSON.parse(item) : undefined
    }
}