import axios from "axios";
import {API_URL} from "../config";

export default function request(api,method = "get",data){
    return new Promise(resolve => {
        // let config = {headers:{
        //     'Access-Control-Allow-Origin':'*'
        //     }}
        axios[method](API_URL + api,data).then(response=>{
            if (response.data.code == 200){
                resolve({
                    success:true,
                    data:response.data.body,
                })
            }else {
                resolve({
                    success:false,
                    msg:response.data.msg,
                })
            }
        }).catch((err)=>{
            resolve({
                success:false,
                data:err,
                msg:"网络连接失败"
            })
        })
    })
}
