
module.exports = {
    API_URL:"https://w.0744trip.com/o2o/zjj/",
    // API_URL:"http://192.168.110.125:8080/o2o/zjj/",
    // API_URL:"https://wlyfx.0744.cn:8443/",
    // LOCAL_URL:"http://b.u-dan.com",
    STORAGE:{
        SIGN:"sign",
        MID:"mid",
        TOKEN:"token",
        LOGIN_DATA:"loginData",
        COMMENT_ID:"commentId",
    },

}
