import Vue from 'vue';
import App from './App.vue';
import router from "@/router/router";
import store from "@/store/store";
import api from "@/utils/api";
import {Toast,Icon,Button,Popup, Picker, Field,Cell,Collapse, CollapseItem,Calendar} from "vant";
import NavBar from "@/components/NavBar";
// import tracking from 'tracking';


Vue.config.productionTip = false;
Vue.use(Toast).use(Icon).use(Button).use(Popup).use(Picker).use(Field).use(Cell).use(Collapse).use(CollapseItem).use(Calendar);
Vue.component("NavBar",NavBar)


// Object.defineProperty(Vue.prototype, '$tracking', { value: tracking });
Vue.prototype.api = api;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
