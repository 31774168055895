import Vue from "vue";
import Router from "vue-router";
import store from "../store/store";
import storage from "@/utils/storage";
import api from "../utils/api";
// import {WX_BACK_URL} from "../config";

//直接引入页面
import Index from "@/pages/Index";
//懒加载页面
const Issue = () => import("@/pages/Issue");
const Ticket = () => import("@/pages/DigitalTickets");
const Order = () => import("@/pages/OrderDetail");
const Face = () => import("@/pages/Face");
const Suggest = () => import("@/pages/Suggest")

Vue.use(Router);


const router = new Router({
    mode: "history",
    routes: [
        // {}
        {path: "/", name: "index", component: Index},
        {path: "/c", name: "issue", component: Issue},
        {path: "/ticket", name: "ticket", component: Ticket},
        {path: "/face", name: "face", component: Face},
        {path: "/order", name: "order", component: Order, prop: true},
        {path:"/suggest",name:"suggest",component:Suggest}
    ]
});
const beforeIndex = (to, next) => {
    if (to.query.sign) {
        store.commit('setSign', to.query)
        api.getOrderByCode().then(apiRes => {
            if (apiRes.success) {
                store.commit('setFrom', 'o')
                store.commit('setOrderData', apiRes.data)
                storage.setSync("SIGN", to.query)
                next("/order")
            } else {
                next()
            }
        })
    } else if (store.state.from === "c") {
        api.getOrderByCode().then(apiRes => {
            if (apiRes.success) {
                store.commit('setFrom', 'o')
                store.commit('setOrderData', apiRes.data)
                next("/order")
            } else {
                next()
            }
        })
    } else if (store.state.orderData) {
        next('/order')
    } else if (store.state.sign) {
        api.getOrderByCode().then(apiRes => {
            if (apiRes.success) {
                store.commit('setOrderData', apiRes.data)
                next("/order")
            } else {
                next()
            }
        })
    } else {
        next()
    }
}
const beforeIssue = (to, next) => {
    if (to.query.sign) {
        store.commit('setSign', to.query)
        api.queryOrder().then(apiRes => {
            if (apiRes.success) {
                store.commit('setFrom', 'c')
                store.commit('setOrderData', apiRes.data)
                storage.setSync("SIGN", to.query)
                next()
            } else {
                next('/')
            }
        })
    } else if (store.state.sign) {
        api.queryOrder().then(apiRes => {
            if (apiRes.success) {
                store.commit('setOrderData', apiRes.data)
                next()
            } else {
                next('/')
            }
        })
    } else {
        next('/')
    }
}
const beforeOrder = (to, next) => {
    if (store.state.from === "c") {
        api.getOrderByCode().then(apiRes => {
            if (apiRes.success) {
                store.commit('setFrom', 'c')
                store.commit('setOrderData', apiRes.data)
                next()
            } else {
                next('/')
            }
        })
    } else if (store.state.orderData) {
        next()
    } else {
        next('/')
    }
}

// const beforSuggest = (to,next)=>{
//
// }

//设置全局守卫
router.beforeEach((to, from, next) => {
    // store.actions.upDateRouterHistory();
    // store.dispatch("upDateRouterHistory");
    // if (to.name === "suggest") {
    //     next()
    // } else
        if (to.name === "index") {
        beforeIndex(to, next)
    } else if (to.name === "issue") {
        beforeIssue(to, next)
    } else if (to.name === "order") {
        beforeOrder(to, next)
    } else if (to.name === "face" && store.state.orderData) {
        next()
    } else {
        next('/')
    }
});


export default router;
