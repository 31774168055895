import request from "@/utils/request";
import store from "@/store/store";

const api = {
    /**
     * 获取订单详情
     * @param data
     */
    getOrderByCode: function () {

        return request( "query.do?orderNum="+store.state.sign.orderNum+"&sign="+store.state.sign.sign, "get",{})
    },

    /**
     * 上传人脸
     * @param data
     */
    upLoadFace: function (data) {
        return request("uploadFace.do", "post", data)
    },

    /**
     * 上传优惠证件
     * @param data {number:"订单号",stationId:站口ID,timeId:预约时间段ID}
     */
    uploadIdcard: function (data) {
        return request("uploadCard.do", "post", data)
    },

    /**
     * 查询二次预约订单
     * @param id
     * @returns {Promise | Promise<unknown>}
     */
    queryOrder:function (){
        return request("queryOrder.do?orderNum="+store.state.sign.orderNum+"&sign="+store.state.sign.sign, "get", {})
    },

    /**
     * 查询二次预约订单
     * @param id
     * @returns {Promise | Promise<unknown>}
     */
    createOrder:function (data){
        return request("createOrder.do", "post", data)
    }
};

export default api;
