<template>
  <div class="page column-center-center">
    <div class="noOrderTips">未查询到新的订单数据</div>
    <!--    首页(链接未获取到code或code无效，实现输入手动查询订单，或继续上一个订单)-->
    <!--    <van-button v-if="code" type="primary" @click="continueOrder">继续操作上个订单</van-button>-->
  </div>
</template>

<script>
export default {
  name: "Index",
  data() {
    return {
      // sign:{}
    }
  },
  // computed: {
  //   sign() {
  //     return this.$store.state.sign
  //   }
  // },
  // watch: {
  //   sign: {
  //     handler(value) {
  //       if (value) {
  //         if (this.$store.state.from === 'o') {
  //           this.api.getOrderByCode(value).then(apiRes => {
  //             if (apiRes.success) {
  //               this.$store.commit("setOrderData", apiRes.data)
  //               this.$router.push("/order")
  //             }
  //           })
  //         }else if (this.$store.state.from === 'c'){
  //           this.api.queryOrder(this.sign).then(apiRes => {
  //             if (apiRes.success) {
  //               this.$store.commit("setOrderData", apiRes.data)
  //               this.$router.push("/c")
  //             }
  //           })
  //         }
  //       }
  //     },
  //     immediate: true,
  //     deep: true
  //   }
  // },
}
</script>

<style scoped>
.page {
  height: 100%;
}

.noOrderTips {
  margin-bottom: 2rem;
}
</style>
