<template>
  <div class="bar row-between-center">

<!--  -->
    <div class="start row-start-center" style="flex: 1">
      <van-icon v-if="back" name="arrow-left" size="1rem" color="#F0F8FF" @click="leftBtn"/>
    </div>
<!--  -->
    <div style="flex: 2" class="center">
      <slot></slot>
    </div>
<!--  -->
    <div class="end row-end-center" style="flex: 1">

    </div>
<!--  -->

  </div>
</template>

<script>
export default {
  name: "NavBar",
  props:['back'],
  computed:{
    his(){
      return this.$store.state.routerHistory?this.$store.state.routerHistory:0
    }
  },
  methods:{
    leftBtn(){
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
.bar{
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 2.2rem;
  background-color: #00b453;
  z-index: 888;
}
.start {
  padding-left: .5rem;
}
.center{
  font-size: .6rem;
  color: #FFFFFF;
}
.end{
  padding-right: .5rem;
}
</style>
