import Vue from "vue";
import Vuex from "vuex";

import storage from "../utils/storage";
// import api from "@/utils/api";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        from:"o",
        sign: storage.getSync("SIGN") || false,
        orderData: false,
        showNotice:false,
        hideNotice:false,
        visitedPlayer:{},
        showNavBar: true,
        remNum: 1,
        winHeight: 1,
        station: {text: "", id: 0},//当前选择的入园地址
        time: {text: "", id: 0},//当前选择的入园时间
        routerHistory:false,
    },
    mutations: {
        setFrom(state, from) {
            state.from = from;
        },
        setRem(state, num) {
            state.remNum = num;
        },
        setWinHeight(state, data) {
            state.winHeight = data;
        },
        setShowNotice(state,type){
            state.showNotice = type
        },
        setHideNotice(state,type){
            state.hideNotice = type
        },
        setOrderData(state, orderData) {
            state.orderData = orderData;
        },
        setPlayer(state,player){
            state.visitedPlayer = player
        },
        setSign(state, sign) {
            state.sign = sign;
        },
        updateTicket(state, data) {
            state.productList[data.pIndex].infos[data.tIndex].faceStatus = 1;
        },
        setStation(state, data) {
            state.station = data
        },
        setTime(state, data) {
            state.time = data
        },
        setRouterHistory(state,data){
            state.routerHistory = data
        }
    },
    actions: {
        upDateRouterHistory({commit}){
            // console.log(window.history.length)
            commit("setRouterHistory",window.history.length > 1)
        },
        // getOrderData({commit},sign){
        //     api.getOrderByCode({
        //         orderNum: sign.orderNum,
        //         sign: sign.sign
        //     }).then(apiRes => {
        //         if (apiRes.success) {
        //             commit('setsign', sign)
        //             commit('setOrderData', apiRes.data)
        //             storage.setSync("sign", sign)
        //             // next('order')
        //         } else {
        //             commit('setsign', false)
        //             commit('setOrderData', false)
        //         }
        //     })
        // }
        // login({commit}, loginData) {
        //     let token = {
        //         expire: loginData.expire_time,
        //         token: loginData.token
        //     }
        //     commit("setToken", token);
        //     storage.setSync("TOKEN", token)
        // }
    },
    getters: {
        orderData(state){
            return state.orderData
        }
        // isLogin(state) {
        //     return state.nowTime.valueOf() < new Date(state.token.expire).valueOf()
        // }
    }
})
